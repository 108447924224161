import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faBullhorn, faBars, faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import {
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faBullhorn, faBars, faCaretDown,
)

config.mutateApproach = 'sync'
dom.watch()
