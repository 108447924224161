// Stylesheets
require('stylesheets/site.sass')

// Require jquery
global.$ = require("jquery")

// Images
require.context('../images', true)

// Require defaults
require("@rails/ujs").start()
require("turbolinks").start()

// Font Awesome
import "src/fontawesome/site"

// Foundation
import { Foundation } from 'foundation-sites/js/foundation.core'
import { Equalizer } from 'foundation-sites/js/foundation.equalizer'
import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas'

Foundation.addToJquery($)

Foundation.plugin(Equalizer, 'Equalizer')
Foundation.plugin(OffCanvas, 'OffCanvas')

// LEGACY JS START

// Modules
import 'src/modules/site/widget_demo.coffee'
import 'src/modules/site/trial_calculator.coffee'

// Init
import 'src/init/site.coffee'
import 'src/init/turbolinks.coffee'

// LEGACY JS END

// Stimulus
// import "controllers/site"
