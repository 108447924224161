window.App ||= {}

document.addEventListener 'turbolinks:load', (event) ->

  # Init foundation
  $(document).foundation()

  # Init modules
  App.init()

  if window.ga != undefined
    ga('set', 'location', location.href.split('#')[0])
    ga('send', 'pageview', {"title": document.title})
  else if window._gaq != undefined
    _gaq.push(['_trackPageview'])
  else if window.pageTracker != undefined
    pageTracker._trackPageview()

document.addEventListener 'turbolinks:before-cache', (event) ->
  # Remove dynamic js things that should not be cached
