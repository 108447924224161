window.App ||= {}

App.trial_calculator = ->

  $calc_users = $('#calc-users')
  $calc_price = $('#calc-price')

  $users_per_month  = $('[data-calc-input~=users-per-month]')
  $trials_per_month = $('[data-calc-result~=trials-per-month]')
  $trials_per_year  = $('[data-calc-result~=trials-per-year]')
  $members_per_year = $('[data-calc-result~=members-per-year]')
  $revenue_per_year = $('[data-calc-result~=revenue-per-year]')
  $time_per_year    = $('[data-calc-result~=time-per-year]')
  # $min_roi = $('[data-calc-result~=min-roi]')
  # $max_roi = $('[data-calc-result~=max-roi]')
  $roi = $('[data-calc-result=roi]')

  $calc_users.on 'input change', (e) ->
    $users_per_month.html($(@).val())
    calculate()

  $calc_price.on 'change', (e) ->
    calculate()

  calculate = ->
    users = $calc_users.val()
    price = $calc_price.val()

    trials_per_month = users / 100
    trials_per_year  = trials_per_month * 12
    members_per_year = trials_per_year / 2
    revenue_per_year = (members_per_year * price)
    time_per_year    = (trials_per_year) / 4
    # min_roi = Math.floor(revenue_per_year / 997)
    # max_roi = Math.floor(revenue_per_year / 497)
    roi = Math.floor(revenue_per_year / 500)

    $trials_per_month.html(trials_per_month)
    $trials_per_year.html(trials_per_year)
    $members_per_year.html(members_per_year)
    $revenue_per_year.html(revenue_per_year.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'"))
    $time_per_year.html(time_per_year)
    # $min_roi.html(min_roi)
    # $max_roi.html(max_roi)
    $roi.html(roi)

    if roi < 10
      $('.calc__bad-roi').show()
      $('.calc__good-roi').hide()
    else
      $('.calc__bad-roi').hide()
      $('.calc__good-roi').show()
