window.App ||= {}

App.widget_demo = ->

  document.addEventListener "turbolinks:before-cache", ->
    $('script#trial-form-demo-js').remove()
    $('.takashi').remove()

  prepare_trial_form = ->
    # Check if .takashi exists
    if(document.querySelector('.takashi')!=null)
      $('#trial-form-demo').attr('disabled', false)
      $('#trial-form-demo').html($('#trial-form-demo').data('ready'))
      # $('#trial-form-demo').html($('.takashi'))
      # $('.takashi-modal').removeClass('takashi-modal--hidden')
      # $('[data-takashi-behaviour~=close-modal]').removeAttr('data-takashi-behaviour')
    else
      # Wait 50ms if not
      $('#trial-form-demo').attr('disabled', true)
      setTimeout(->
        prepare_trial_form()
      , 100)

  if(document.querySelector('#trial-form-demo')!=null)
    js_link = $("<script>", {
      src: $('#trial-form-demo').data('embed'),
      async: "async",
      id: 'trial-form-demo-js'
    })
    js_link.appendTo('head')

    prepare_trial_form()
